import { store } from '../store.index'
import { API_ROOT } from 'Config'
import axios from 'axios'

export default ({
  requestType = 'get',
  center = localStorage.getItem('selected_center_id'),
  url,
  data = {}
}) => {
  let requestUrl = `${API_ROOT}/api/embed/${url}`
  const rwgToken = localStorage.getItem('rwg_token')
  let axiosConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('customerkey'),
      'x-aws-waf-token': localStorage.getItem('aws-waf-token'),
      'x-rwg-token': rwgToken || ''
    }
  }

  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix,
    center_id: center
  }

  if (requestType === 'post') {
    if (data instanceof FormData)
      axiosConfig.headers['Content-Type'] = 'multipart/form-data'
    //since upgrading to axios 1.6.7 we check data is in the form of formData
    return axios[requestType](requestUrl, data, axiosConfig)
  }

  return axios[requestType](requestUrl, axiosConfig)
}

/**
 *
 * @param {Object} Label (key, value)
 * @returns {String} Label Text
 */
export const overrideLabel = ({ key, value, capitalize = false }) => {
  const clientSettings = JSON.parse(localStorage.getItem('clientSettings'))
  const label = clientSettings?.[key] ?? value ?? ''
  return capitalize ? label.charAt(0).toUpperCase() + label.slice(1) : label
}
