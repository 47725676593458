import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'normalize.css'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import * as serviceWorker from './registerServiceWorker'
import './index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { PROD, POSTHOG_APIKEY } from 'Config'
import { store, persistor } from './store.index'
import LogRocket from 'logrocket'
import posthog from 'posthog-js'
import Clarity from '@microsoft/clarity'

// Initialize microsoft clarity
Clarity.init('qcoatnthm3')

Sentry.init({
  enabled: true,
  dsn:
    'https://96dd88f36a184aa8a37c2d5d226b4b78@o443412.ingest.sentry.io/5454988',
  integrations: [
    new Integrations.BrowserTracing(),
    new posthog.SentryIntegration(posthog, 'bookee-apps', 5454988)
  ],
  tracesSampleRate: 0,
  environment: PROD ? 'production' : 'development',
  ignoreErrors: ['Non-Error promise rejection captured', 'Loading chunk'],
  autoSessionTracking: true
  // beforeSend(event, _) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   console.log('ev', event)
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id })
  //   }
  //   return event
  // },
})

if (POSTHOG_APIKEY) {
  posthog.init(POSTHOG_APIKEY, { api_host: 'https://app.posthog.com' })
  // posthog.init('phc_1bJHGr24VQNqQ2aj9d0iqIJ8T9FgoFiQ68ISJlLby6K', {api_host: 'https://app.posthog.com'})
  let contact = localStorage.getItem('contact')
  let clientSettings = localStorage.getItem('clientSettings')
  let url_prefix = localStorage.getItem('url_prefix')
  if (contact) {
    contact = JSON.parse(contact)
    clientSettings = JSON.parse(clientSettings)
    posthog.identify(contact.email, {
      email: contact.email,
      first_name: contact.first_name,
      last_name: contact.last_name,
      org: clientSettings?.name
    })
    posthog.group('company', url_prefix, {
      business: clientSettings?.name
    })
  }
}

// Enable logrocket sessions
if (window.location.host.indexOf('mandalayogacollective') > -1) {
  LogRocket.init('lbxoim/bookee-iframe')
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('logRocketURL', sessionURL)
    })
  })
} else if (window.location.host.indexOf('fitandbendy') > -1) {
  LogRocket.init('dmriwm/bookee-iframe-fitandbendy')
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('logRocketURL', sessionURL)
    })
  })
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
