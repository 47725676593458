/* eslint-disable react/jsx-indent */
import Clarity from '@microsoft/clarity'

export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ]

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
  // let timein = ( time.join ("").replace(/:[^:]*$/,'') );
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.port != '' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export function getUrlParams(param) {
  let host = window.location.host.split('.')
  isLocalhost
    ? localStorage.setItem('url_prefix', 'f10boxing')
    : localStorage.setItem('url_prefix', host[0])

  Clarity.setTag('url_prefix', localStorage.getItem('url_prefix'))
  return localStorage.getItem('url_prefix')
}

export function daysInThisMonth() {
  var now = new Date()
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
}

export function replaceImage(e, image) {
  e.target.src = image
}

export function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
